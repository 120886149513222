import Newsletter from 'components/Common/Newsletter'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { remCalc, respondTo } from 'styles/mixins'

const StyledNewsletter = styled(Newsletter)`
  margin-bottom: ${remCalc(64)};

  ${respondTo.mdAndUp`
    max-width: ${remCalc(840)};
    margin-bottom: ${remCalc(98)};
  `}
`

const WideNewsletter = ({ newsletterGaData, newsletterGa4CustomizedParameters }) => {
  return (
    <StyledNewsletter
      newsletterGaData={newsletterGaData}
      newsletterGa4CustomizedParameters={newsletterGa4CustomizedParameters}
    />
  )
}

Newsletter.propTypes = {
  newsletterGaData: PropTypes.object,
}

export default WideNewsletter
